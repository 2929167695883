import { getHeaders } from '@/utils/getHeaders';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';


interface DadosCadastrais {

}

@Injectable({
  providedIn: 'root'
})
export class DadosCadastraisService {
  private api = environment.endpoint_api
  public dadosCadastrais: any = {}
  public _dadosCadastraisPublico: BehaviorSubject<any> = new BehaviorSubject<any>({})

  dadosCadastraisPublico$: any = this._dadosCadastraisPublico.asObservable()

  constructor(
    private http: HttpClient
  ) {}

  getDadosCadastrais(idUsuario: number | string) {
    const classeContexto = this
    const headers = getHeaders()

    this.http.get(this.api + "/Usuario/ObterDadosCadastrais/" + idUsuario, { headers }).subscribe({
      next(response) {
         classeContexto.dadosCadastrais = response
         classeContexto._dadosCadastraisPublico.next(response)
      },
      error(error) {
      }
    })
  }
  getPerfis() {
    const headers = getHeaders()

    this.http.get(this.api + "/Perfil/ListarAtivos", { headers }).subscribe({
      next(response) {
      },
      error(error) {
      }
    })
  }

}
