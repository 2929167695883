import {getHeaders} from '@/utils/getHeaders';
import {urlEncode} from '@/utils/urlEncoded';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {environment} from 'environments/environment';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    public user: any;
    private api = environment.endpoint_api;

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private http: HttpClient,
        private modalService: NgbModal
    ) {}

    public getDadosUsuarioLogadoStorage() {
        this.user = localStorage.getItem('user');
        const userStorage = localStorage.getItem('user');
        if (userStorage) {
            this.user = JSON.parse(userStorage);
            return JSON.parse(userStorage);
        } else {
            return {};
        }
    }

    public openModal(component, options = {size: 'lg', backdrop: false}) {
        return this.modalService.open(component, options);
    }

    public closeModal() {
        return this.modalService.dismissAll();
    }

    // No serviço AppService
    async loginByAuth({email, senha}): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            const body = urlEncode({
                username: email,
                password: senha,
                grant_type: 'password'
            });
            const headers = getHeaders({
                'Content-Type': 'application/x-www-form-urlencoded'
            });

            this.http
                .post(this.api + `/security/token`, body, {headers})
                .subscribe({
                    next: (response: {access_token: string}) => {
                        const access_token = response.access_token;
                        const headers = getHeaders({
                            Authorization: `bearer ${access_token}`
                        });

                        this.http
                            .get(this.api + `/Usuario/ObterUsuarioLogado`, {
                                headers
                            })
                            .subscribe({
                                next: (usuario: any) => {
                                    this.toastr.success(
                                        'Login efetuado com sucesso.'
                                    );
                                    this.user = usuario;
                                    localStorage.setItem('token', access_token);
                                    localStorage.setItem(
                                        'user',
                                        JSON.stringify(usuario)
                                    );

                                    if (usuario?.RedefinirSenha) {
                                        localStorage.setItem(
                                            'RedefinirSenha',
                                            JSON.stringify(
                                                usuario?.RedefinirSenha
                                            )
                                        );
                                        this.router.navigate([
                                            '/redefinir-senha'
                                        ]);
                                        resolve(usuario);
                                        return;
                                    }

                                    if (
                                        [1, 3, 4].includes(usuario?.Perfil?.ID)
                                    ) {
                                        this.router.navigate([
                                            '/dashboard/acesso-avaliador'
                                        ]);
                                    } else {
                                        this.router.navigate([
                                            '/dashboard/acesso-aluno'
                                        ]);
                                    }
                                    resolve(usuario);
                                },
                                error: (error) => {
                                    reject(error); // Rejeite a promise em caso de erro ao obter o usuário
                                }
                            });
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
        });
    }

    async obtemUsuarioLogado(idUsuario): Promise<any> {
        const headers = getHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        this.http
            .get(
                this.api +
                    `/Usuario/ObterUsuario/${idUsuario}
                `,
                {headers}
            )
            .subscribe({
                next: (usuario: any) => {
                    this.user = usuario;
                    localStorage.setItem('user', JSON.stringify(usuario));
                },
                error: (error) => {
                    console.log(error);
                }
            });
    }

    async recuperarSenha(email): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http
                .post(this.api + `/Usuario/RecuperarSenha`, {Email: email})
                .subscribe({
                    next: (res: any) => {
                        resolve(res);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
        });
    }

    async registerUser(body): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            const headers = getHeaders({'Content-Type': 'application/json'});

            this.http
                .post(this.api + `/Usuario/ValidarAutoCadastroEtapa1`, body, {
                    headers
                })
                .subscribe({
                    next: (response) => {
                        resolve(response); // Resolva a promise em caso de sucesso ao fazer cadastro
                    },
                    error: (error) => {
                        reject(error); // Rejeite a promise em caso de erro ao fazer cadastro
                        //retorna erro
                        return error;
                    }
                });
        });
    }

    obterDadosConvite(hash: string) {
        const headers = getHeaders();
        return this.http.get(this.api + '/Usuario/DadosConvite/' + hash, {
            headers
        });
    }

    async reenviarCodigo(body): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            const headers = getHeaders({'Content-Type': 'application/json'});

            this.http
                .post(this.api + `/Usuario/ReenviarCodigoAutoCadastro`, body, {
                    headers
                })
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error: (error) => {
                        reject(error);
                        return error;
                    }
                });
        });
    }

    async validarCodigo(body): Promise<any> {
        return new Promise((resolve, reject) => {
            localStorage.clear();
            const headers = getHeaders({'Content-Type': 'application/json'});

            this.http
                .post(this.api + `/Usuario/SalvarAutoCadastro`, body, {headers})
                .subscribe({
                    next: (response) => {
                        resolve(response);
                    },
                    error: (error) => {
                        reject(error);
                        return error;
                    }
                });
        });
    }

    async getProfile() {
        const headers = getHeaders();
        return this.http
            .get(this.api + `/Usuario/ObterUsuarioLogado`, {headers})
            .subscribe({
                next: (usuario: any) => {
                    this.user = usuario;
                    localStorage.setItem('user', JSON.stringify(usuario));
                },
                error: (error) => {
                    this.logout();
                    throw error;
                }
            });
    }

    async verificarEmail(email) {
        return new Promise((resolve, reject) => {
            this.http
                .get(this.api + `/Usuario/VerificarPreCadastro?email=${email}`)
                .subscribe({
                    next: (response: any) => {
                        resolve(response);
                    },
                    error: (error) => {
                        reject(error);
                    }
                });
        });
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        this.user = null;
        this.router.navigate(['/login']);
    }

    getObterMenus() {
        const headers = getHeaders();
        return this.http.get(this.api + `/Usuario/ObterMenu`, {headers});
    }
}
