<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">Relatório dos meus CHECK-UPS</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="atualizarListagemRelatorio()"
            [formGroup]="formCadastro"
            class="container-tabela p-4"
        >
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar Por
            </h6>

            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Avaliador</span
                        >
                        <ng-select
                            [items]="listaAvaliadores"
                            bindLabel="Nome"
                            bindValue="ID"
                            class="form-input-box-select"
                            formControlName="AvaliadorID"
                            [virtualScroll]="true"
                            [searchable]="true"
                        >
                        </ng-select>
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Data início
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="DataInicio"
                            formControlName="DataInicio"
                            type="date"
                        />
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Data Fim
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="DataFim"
                            formControlName="DataFim"
                            type="date"
                        />
                    </div>

                    <div class="col-md-2 col-12 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="submit"
                            [disabled]="loading"
                        >
                            {{ loading ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                Resultado
            </h2>

            <div
                class="col-12 col-md-3 mt-2"
                *ngIf="!nenhumRegistro && !loading"
            >
                <button
                    class="btn btn-secondary btn-block mt-4"
                    style="width: 110px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarPDF()"
                >
                    Baixar PDF
                </button>
            </div>
            <div
                class="col-12 col-md-3 mt-2 mb-2"
                *ngIf="!nenhumRegistro && !loading"
            >
                <button
                    class="btn btn-secondary btn-block mt-4"
                    style="width: 160px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarExcel()"
                >
                    Baixar Excel
                </button>
            </div>

            <table
                class="table table-striped"
                style="max-height: 600px; overflow-y: auto"
            >
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Cadastro Novos
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            CHECK-UPS
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            CHECK-UPS feitos novamente
                        </th>
                        <th class="th-tabela" style="color: #b91520">Z1</th>
                        <th class="th-tabela" style="color: #b56f00">Z2</th>
                        <th class="th-tabela" style="color: #9c930d">Z3</th>
                        <th class="th-tabela" style="color: #14558b">Z4</th>
                        <th class="th-tabela" style="color: #298021">Z5</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!nenhumRegistro && !loading">
                        <tr
                            *ngFor="
                                let relatorio of relatorioCheckupList?.Lista;
                                let i = index
                            "
                        >
                            <td>{{ relatorio?.NomeAvaliador }}</td>
                            <td>{{ relatorio?.NovosClientes }}</td>
                            <td>{{ relatorio?.NovosCheckups }}</td>
                            <td>{{ relatorio?.CheckupsFeitoNovamente }}</td>
                            <td style="color: #b91520">
                                {{ relatorio?.ListaZ[0].Quantidade }}
                            </td>
                            <td style="color: #b56f00">
                                {{ relatorio?.ListaZ[1].Quantidade }}
                            </td>
                            <td style="color: #9c930d">
                                {{ relatorio?.ListaZ[2].Quantidade }}
                            </td>
                            <td style="color: #14558b">
                                {{ relatorio?.ListaZ[3].Quantidade }}
                            </td>
                            <td style="color: #298021">
                                {{ relatorio?.ListaZ[4].Quantidade }}
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loading">
                        <td class="text-center align-middle" colspan="12">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="nenhumRegistro && !loading">
                        <td class="text-center" colspan="12">
                            Nenhum relatório encontrado, por favor insira um
                            filtro.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
