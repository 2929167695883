<div style="background-color: #0202ff">
    <app-loading-login-page *ngIf="carregandoLogin"></app-loading-login-page>
    <section class="home-half" id="home" *ngIf="!carregandoLogin">
        <!-- <div class="bg-overlay"></div> -->
        <div class="home-center">
            <div class="home-desc-center">
                <div class="container">
                    <div class="row vertical-content">
                        <div class="col-lg-5 col-md-6 col-sm-8 mr-auto">
                            <div
                                class="d-flex justify-content-center align-items-center"
                            >
                                <img
                                    src="../../../assets/img/logoSafeTextoBranco.png"
                                    alt=""
                                    class="logo-light"
                                    height="400px"
                                    width="400px"
                                />
                            </div>

                            <div class="pt-4">
                                <p class="home-subtitle-text">
                                    Bem-vindo a Nova Era da SAÚDE FUNCIONAL
                                    INTEGRATIVA
                                </p>
                                <p
                                    class="text-white mb-4 text-uppercase text-center"
                                >
                                    Bem vindo a melhor e mais completa
                                    Plataforma de avaliação de saúde funcional
                                    integrativa do mundo.
                                </p>
                                <p
                                    class="text-white mb-4 text-uppercase text-center"
                                >
                                    Você está na Nova Era da Saúde Funcional
                                    Integrativa com a Plataforma SAFE.
                                </p>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6 col-sm-8">
                            <div
                                class="home-registration-form bg-white p-5 d-flex flex-column"
                            >
                                <h4 class="form-title">LOGIN</h4>
                                <p class="form-subtitle mb-5">
                                    ENTRE COM SEUS DADOS
                                </p>
                                <form
                                    class="registration-form p-3 flex-grow-1"
                                    [formGroup]="formLogin"
                                    (ngSubmit)="onClickLogin()"
                                >
                                    <label class="form-texts">Email</label>
                                    <input
                                        formControlName="email"
                                        name="email"
                                        id="email"
                                        type="email"
                                        class="form-control registration-input-box"
                                    />

                                    <label class="form-texts mt-2">Senha</label>
                                    <div class="position-relative">
                                        <input
                                            [type]="
                                                mostrarSenha
                                                    ? 'text'
                                                    : 'password'
                                            "
                                            formControlName="senha"
                                            id="senha"
                                            name="senha"
                                            class="form-control registration-input-box"
                                        />
                                        <button
                                            type="button"
                                            class="btn btn-link position-absolute"
                                            style="
                                                top: 55%;
                                                right: 25px;
                                                transform: translateY(-50%);
                                                font-size: 20px;
                                                padding: 2px 5px;
                                            "
                                            (click)="toggleMostrarSenha()"
                                            aria-label="Toggle password visibility"
                                        >
                                            <i
                                                class="material-icons material-icons-outlined"
                                            >
                                                {{
                                                    mostrarSenha
                                                        ? 'visibility_off'
                                                        : 'visibility'
                                                }}
                                            </i>
                                        </button>
                                    </div>

                                    <div
                                        class="form-check mt-2 justify-content-end align-items-end d-flex"
                                    >
                                        <a
                                            routerLink="/recuperar-senha"
                                            class="mr-3"
                                            >Esqueci minha senha?</a
                                        >
                                    </div>

                                    <button
                                        *ngIf="carregandoLogin"
                                        disabled
                                        class="btn btn-azul-entrar w-100 text-uppercase mt-4"
                                        type="submit"
                                    >
                                        Carregando
                                    </button>

                                    <button
                                        *ngIf="carregandoLogin === false"
                                        class="btn btn-azul-entrar w-100 text-uppercase mt-4"
                                        type="submit"
                                    >
                                        Entrar
                                    </button>
                                    <a
                                        class="btn btn-cinza-cadastrar w-100 text-uppercase mt-4"
                                        type="button"
                                        routerLink="/cadastro"
                                    >
                                        Cadastrar
                                    </a>
                                </form>
                                <a
                                    routerLink="/graficos-plataforma"
                                    class="text-center mt-auto color-amarelo-entrar"
                                >
                                    Acessar gráficos SAFE®
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
