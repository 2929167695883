<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5 text-amarelo-plataforma-safe">
                    Senhas provisórias
                </h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>

            <div
                class="container-tabela mt-5"
                style="max-height: 600px; overflow-y: auto"
            >
                <h2
                    class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
                >
                    Senhas provisórias de hoje: {{ listaClientes.length }}
                </h2>

                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th
                                class="th-tabela text-azul-claro-plataforma-safe"
                            >
                                Data
                            </th>
                            <th
                                class="th-tabela text-azul-claro-plataforma-safe"
                            >
                                Nome
                            </th>
                            <th
                                class="th-tabela text-azul-claro-plataforma-safe"
                            >
                                E-mail
                            </th>
                            <th
                                class="th-tabela text-azul-claro-plataforma-safe"
                            >
                                Celular
                            </th>
                            <th
                                class="th-tabela text-azul-claro-plataforma-safe"
                            >
                                Senha
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listaClientes; let i = index">
                            <td>{{ formatDate(item.Data) }}</td>
                            <td>{{ item.Nome }}</td>
                            <td>{{ item.Email }}</td>
                            <td>{{ item.Telefone }}</td>
                            <td>{{ item.Senha }}</td>
                        </tr>
                        <tr
                            *ngIf="
                                nenhumaSenhaEncontrada && !loadingListaClientes
                            "
                        >
                            <td class="text-center" colspan="5">
                                Nenhuma senha provisória gerada hoje.
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div
                    *ngIf="loadingListaClientes"
                    class="d-flex justify-content-center align-items-center"
                    style="flex: 1"
                >
                    <div
                        class="spinner-border text-azul-plataforma-safe"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
