import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {createMask} from '@ngneat/input-mask';
import {AppService} from '@services/app.service';
import {ToastrService} from 'ngx-toastr';
import {split} from '@angular-devkit/core';

@Component({
    selector: 'app-register-page-cupom',
    templateUrl: './register-page-cupom.component.html',
    styleUrls: ['./register-page-cupom.component.scss']
})
export class RegisterPageCupomComponent implements OnInit {
    public formRegister: FormGroup;
    public carregandoLogin: boolean = false;
    public loadingObterDadosConvite: boolean = false;
    public carregandoReenvia: boolean = false;
    public carregandoValida: boolean = false;
    public mostrarSenha: boolean = false;
    public mostrarConfirmarSenha: boolean = false;
    public etapa: number = 1;
    public urlCodeHash: any = '';
    public avaliadorNome: any = '';
    public CodigoPais = [
        {
            label: 'Brasil',
            value: '55'
        },
        {
            label: 'Estados Unidos',
            value: '1'
        },
        {
            label: 'Outro',
            value: '0'
        }
    ];
    public Sexo = [
        {
            label: 'Masculino',
            value: '1'
        },
        {
            label: 'Feminino',
            value: '2'
        }
    ];
    public mascaraTelefone: string = '(99) 99999-9999';

    constructor(
        private toastr: ToastrService,
        private appService: AppService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.formRegister = new FormGroup({
            CodigoPais: new FormControl('', [Validators.required]),
            CodigoCupom: new FormControl('', [Validators.required]),
            AvaliadorID: new FormControl('', [Validators.required]),
            AvaliadorSAFE: new FormControl(true, [Validators.required]),
            CPF: new FormControl('', [Validators.required]),
            Nome: new FormControl('', [Validators.required]),
            Login: new FormControl('', [Validators.required]),
            Email: new FormControl('', [Validators.required]),
            Senha: new FormControl('', [Validators.required]),
            confirmarSenha: new FormControl('', [Validators.required]),
            TelefoneCelular: new FormControl('', [Validators.required]),
            DataNascimento: new FormControl('', [Validators.required]),
            Sexo: new FormControl('', [Validators.required]),
            Ocupacao: new FormControl('', [Validators.required]),
            CodigoSMS: new FormControl('', [Validators.required]),
            SMSEnviado: new FormControl('', [Validators.required]),
            EmailEnviado: new FormControl('', [Validators.required]),
            SMSValidado: new FormControl('', [Validators.required])
        });

        // Inicia a máscara do telefone no formato brasileiro
        this.updatePhoneMask('55');

        // Monitora alterações no código do país para atualizar a máscara do telefone
        this.formRegister.get('CodigoPais').valueChanges.subscribe((value) => {
            this.updatePhoneMask(value);
        });

        const urlSplit = this.router.url.split('/');

        if (urlSplit.length > 2) {
            this.urlCodeHash = urlSplit[urlSplit.length - 1];
            if (this.urlCodeHash) {
                this.formRegister.get('CodigoCupom').setValue(this.urlCodeHash);
                this.obterDadosConvite();
                this.formRegister.get('AvaliadorSAFE').setValue(false);
            }
        } else {
            if (this.urlCodeHash) {
                this.formRegister.get('AvaliadorID').setValue('');
                this.obterDadosConvite();
                this.formRegister.get('AvaliadorSAFE').setValue(false);
            }
        }
    }

    obterDadosConvite(maxTentativas = 3, tentativaAtual = 1) {
        if (this.urlCodeHash && tentativaAtual <= maxTentativas) {
            this.loadingObterDadosConvite = true;
            this.appService.obterDadosConvite(this.urlCodeHash).subscribe({
                next: (response) => {
                    this.avaliadorNome = response;
                    this.loadingObterDadosConvite = false;
                },
                error: (error) => {
                    if (typeof error?.error?.Message === 'string') {
                        this.toastr.error(error?.error?.Message);
                    } else {
                        this.toastr.error(
                            'Ocorreu um erro ao obter os dados do convite'
                        );
                    }
                    this.loadingObterDadosConvite = false;
                    // Tenta novamente após um pequeno intervalo
                    setTimeout(() => {
                        this.obterDadosConvite(
                            maxTentativas,
                            tentativaAtual + 1
                        );
                    }, 1000); // Aguarda 1 segundo antes de tentar novamente
                }
            });
        }
    }

    // Método para atualizar a máscara do telefone com base no código do país
    updatePhoneMask(countryCode: string): void {
        // Defina a máscara do telefone com base no código do país
        this.mascaraTelefone = createMask({
            mask:
                countryCode === '55'
                    ? '(99) 99999-9999'
                    : countryCode === '1'
                    ? '(999) 999-9999'
                    : '',
            keepStatic: true
        });
    }

    onInputChange(event: any) {
        const value = event.target.value;
        const name = event.target.name;

        this.formRegister.get(name).setValue(value);
    }

    async registerUser() {
        if (
            this.formRegister.get('Nome').value === '' ||
            this.formRegister.get('Nome').value === null
        ) {
            this.toastr.error('Preencha o Nome.');
            return;
        }

        if (
            this.formRegister.get('Email').value === '' ||
            this.formRegister.get('Email').value === null
        ) {
            this.toastr.error('Preencha o Email.');
            return;
        }
        if (
            this.formRegister.get('CodigoPais').value === '' ||
            this.formRegister.get('CodigoPais').value === null
        ) {
            this.toastr.error('Selecione o Código do país.');
            return;
        }
        if (
            this.formRegister.get('TelefoneCelular').value === '' ||
            this.formRegister.get('TelefoneCelular').value === null
        ) {
            this.toastr.error('Preencha o Telefone celular.');
            return;
        }
        if (
            this.formRegister.get('DataNascimento').value === '' ||
            this.formRegister.get('DataNascimento').value === null
        ) {
            this.toastr.error('Preencha a Data de nascimento.');
            return;
        }
        if (
            this.formRegister.get('Sexo').value === '' ||
            this.formRegister.get('Sexo').value === null
        ) {
            this.toastr.error('Selecione o Gênero.');
            return;
        }
        if (
            this.formRegister.get('Ocupacao').value === '' ||
            this.formRegister.get('Ocupacao').value === null
        ) {
            this.toastr.error('Preencha a Ocupação.');
            return;
        }
        if (
            this.formRegister.get('Senha').value === '' ||
            this.formRegister.get('Senha').value === null
        ) {
            this.toastr.error('Preencha a Senha.');
            return;
        }
        if (
            this.formRegister.get('confirmarSenha').value === '' ||
            this.formRegister.get('confirmarSenha').value === null
        ) {
            this.toastr.error('Preencha a Confirmação da Senha.');
            return;
        }

        let confirmarSenha = this.formRegister.get('confirmarSenha').value;
        const body = {
            AvaliadorID: this.formRegister.get('AvaliadorID').value,
            AvaliadorSAFE: this.formRegister.get('AvaliadorSAFE').value,
            CodigoCupom: this.formRegister.get('CodigoCupom').value,
            CodigoPais: parseInt(this.formRegister.get('CodigoPais').value),
            Etapa1: {
                CPF: this.formRegister.get('CPF').value,
                Nome: this.formRegister.get('Nome').value,
                Login: this.formRegister.get('Login').value,
                Email: this.formRegister.get('Email').value,
                Senha: this.formRegister.get('Senha').value,
                TelefoneCelular: this.formRegister
                    .get('TelefoneCelular')
                    .value.replace(/\D/g, ''),
                DataNascimento: this.formRegister.get('DataNascimento').value,
                Sexo: this.formRegister.get('Sexo').value,
                Ocupacao: this.formRegister.get('Ocupacao').value
            },
            Etapa2: {
                CodigoSMS: this.formRegister.get('CodigoSMS').value,
                SMSEnviado: false,
                EmailEnviado: false,
                SMSValidado: false
            }
        };

        const errors = [];
        if (!body?.Etapa1?.Nome || body?.Etapa1?.Nome.length < 3) {
            errors.push('Nome deve ter pelo menos 3 caracteres.');
        }

        if (!body?.Etapa1?.Email || body?.Etapa1?.Email.length < 3) {
            errors.push('Email deve ter pelo menos 3 caracteres.');
        }

        if (
            !body?.Etapa1?.TelefoneCelular ||
            body?.Etapa1?.TelefoneCelular.length < 3
        ) {
            errors.push('Telefone celular deve ter pelo menos 3 caracteres.');
        }

        if (
            !body?.Etapa1?.DataNascimento ||
            body?.Etapa1?.DataNascimento.length < 3
        ) {
            errors.push('Data de nascimento deve ter pelo menos 3 caracteres.');
        }

        if (!body?.Etapa1?.Ocupacao || body?.Etapa1?.Ocupacao.length < 3) {
            errors.push('Ocupação deve ter pelo menos 3 caracteres.');
        }

        if (!body?.Etapa1?.Sexo || body?.Etapa1?.Sexo === '') {
            errors.push('Sexo não pode estar vazio.');
        }

        if (!body?.Etapa1?.Senha || body?.Etapa1?.Senha.length < 3) {
            errors.push('Senha deve ter pelo menos 3 caracteres.');
        }

        if (body?.Etapa1?.TelefoneCelular.length < 10) {
            this.toastr.error('Telefone inválido.');
            return;
        }
        if (body?.Etapa1?.Senha !== confirmarSenha) {
            this.toastr.error('As senhas não conferem.');
            return;
        }
        if (body?.Etapa1?.Senha.length < 6) {
            this.toastr.error('A senha deve ter no mínimo 6 caracteres.');
            return;
        }

        this.carregandoLogin = true;
        try {
            const data = await this.appService.registerUser(body);
            this.formRegister.get('AvaliadorID').setValue(data.AvaliadorID);
            this.toastr.success('Usuário cadastrado com sucesso.');
            //atualiza a etapa par 2
            this.etapa = 2;
        } catch (error) {
            if (error?.error?.Message) {
                try {
                    const errorMessage = JSON.parse(error.error.Message);
                    if (Array.isArray(errorMessage)) {
                        let errorMessageString = '';
                        errorMessage.forEach((msg: any) => {
                            errorMessageString += msg.Mensagem + ' ';
                        });
                        this.toastr.error(errorMessageString);
                    } else {
                        this.toastr.error(errorMessage);
                    }
                } catch {
                    this.toastr.error(error.error.Message);
                }
            } else {
                this.toastr.error(
                    'Erro ao registrar usuário. Por favor, tente novamente mais tarde.'
                );
            }
        } finally {
            this.carregandoLogin = false;
        }
    }

    voltarCadastro() {
        this.etapa = 1;
        this.zerarFormulario();
    }

    voltarLogin() {
        this.router.navigate(['/login']);
    }

    zerarFormulario() {
        this.formRegister.reset();
    }

    async reenviarCodigo() {
        let body = {
            Celular: this.formRegister
                .get('TelefoneCelular')
                .value.replace(/\D/g, ''),
            Email: this.formRegister.get('Email').value,
            Codigo: '',
            CodigoPais: this.formRegister.get('CodigoPais').value
        };
        this.carregandoReenvia = true;
        try {
            const cod = await this.appService.reenviarCodigo(body);
            this.toastr.success('Código reenviado com sucesso.');
        } catch (error) {
            if (error?.error?.Message) {
                try {
                    const errorMessage = JSON.parse(error.error.Message);
                    if (Array.isArray(errorMessage)) {
                        let errorMessageString = '';
                        errorMessage.forEach((msg: any) => {
                            errorMessageString += msg.Mensagem + ' ';
                        });
                        this.toastr.error(errorMessageString);
                    } else {
                        this.toastr.error(errorMessage);
                    }
                } catch {
                    this.toastr.error(error.error.Message);
                }
            } else {
                this.toastr.error(
                    'Erro ao reenviar código. Por favor, tente novamente mais tarde.'
                );
            }
        } finally {
            this.carregandoReenvia = false;
        }
    }

    onInputChangeSMS(event: any) {
        const value = event.target.value;
        this.formRegister.get('CodigoSMS').setValue(value.slice(0, 6));
    }

    toggleMostrarSenha(): void {
        this.mostrarSenha = !this.mostrarSenha;
    }

    toggleMostrarConfirmarSenha(): void {
        this.mostrarConfirmarSenha = !this.mostrarConfirmarSenha;
    }

    async validarCodigo() {
        const body = {
            AvaliadorID: this.formRegister.get('AvaliadorID').value,
            AvaliadorSAFE: this.formRegister.get('AvaliadorSAFE').value,
            CodigoCupom: this.formRegister.get('CodigoCupom').value,
            CodigoPais: parseInt(this.formRegister.get('CodigoPais').value),
            Etapa1: {
                Nome: this.formRegister.get('Nome').value,
                Login: '',
                Email: this.formRegister.get('Email').value,
                Senha: this.formRegister.get('Senha').value,
                TelefoneCelular: this.formRegister
                    .get('TelefoneCelular')
                    .value.replace(/\D/g, ''),
                DataNascimento: this.formRegister.get('DataNascimento').value,
                Sexo: this.formRegister.get('Sexo').value,
                Ocupacao: this.formRegister.get('Ocupacao').value
            },
            Etapa2: {
                CodigoSMS: this.formRegister.get('CodigoSMS').value,
                SMSEnviado: false,
                EmailEnviado: false,
                SMSValidado: false
            },
            NaoValidaSMS: false
        };
        try {
            if (body?.Etapa2?.CodigoSMS.length < 3) {
                this.toastr.error('Preencha o código.');
                return;
            }
            this.carregandoValida = true;
            const cod = await this.appService.validarCodigo(body);
            this.toastr.success('Código validado com sucesso.');
            await this.appService.loginByAuth({
                email: body.Etapa1.Email,
                senha: body.Etapa1.Senha
            });
            this.carregandoValida = false;
        } catch (error) {
            if (error?.error?.Message) {
                try {
                    const errorMessage = JSON.parse(error.error.Message);
                    if (Array.isArray(errorMessage)) {
                        let errorMessageString = '';
                        errorMessage.forEach((msg: any) => {
                            errorMessageString += msg.Mensagem + ' ';
                        });
                        this.toastr.error(
                            'Código E-mail não validado, verifique o último código enviado por e-mail'
                        );
                    } else {
                        this.toastr.error(
                            'Código E-mail não validado, verifique o último código enviado por e-mail'
                        );
                    }
                } catch {
                    this.toastr.error(
                        'Código E-mail não validado, verifique o último código enviado por e-mail'
                    );
                }
            } else {
                this.toastr.error(
                    'Erro ao validar código. Por favor, tente novamente mais tarde.'
                );
            }
            this.carregandoValida = false;
        } finally {
            this.carregandoValida = false;
        }
    }
}
