<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">Ranking de Avaliadores</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="atualizarListagemRelatorio()"
            [formGroup]="formCadastro"
            class="container-tabela p-4"
        >
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar Por
            </h6>

            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Avaliador</span
                        >
                        <ng-select
                            [items]="listaAvaliadores"
                            bindLabel="Nome"
                            bindValue="ID"
                            class="form-input-box-select"
                            formControlName="AvaliadorID"
                            [virtualScroll]="true"
                            [searchable]="true"
                        >
                        </ng-select>
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Estado</span
                        >
                        <ng-select
                            [items]="listaUF"
                            bindLabel="nome"
                            bindValue="nome"
                            class="form-input-box-select"
                            formControlName="UF"
                            [virtualScroll]="true"
                            [searchable]="true"
                        >
                        </ng-select>
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Nº min de CHECK-UPS
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="NumeroMinimoDeCheckups"
                            formControlName="NumeroMinimoDeCheckups"
                        />
                    </div>

                    <div class="col-md-3 col-12 mt-2"></div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Data início
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="DataInicio"
                            formControlName="DataInicio"
                            type="date"
                        />
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Data Fim
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="DataFim"
                            formControlName="DataFim"
                            type="date"
                        />
                    </div>

                    <div class="col-md-3col-12 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="submit"
                            [disabled]="loading"
                        >
                            {{ loading ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                Resultado {{relatorioCheckupList?.Resultados?.length > 0 ? '(' +
                relatorioCheckupList?.Resultados?.length + ')' : ''}}
            </h2>

            <span class="text-azul-claro-plataforma-safe ml-3">
                {{formCadastro.value.DataInicio ? 'Período de ' +
                formatDate(formCadastro.value.DataInicio) + ' a ' +
                formatDate(formCadastro.value.DataFim) : ''}}
            </span>

            <div
                class="col-12 col-md-3 mt-2"
                *ngIf="!nenhumRegistro && !loading"
            >
                <button
                    class="btn btn-secondary btn-block mt-4"
                    style="width: 110px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarPDF()"
                >
                    Baixar PDF
                </button>
            </div>
            <div
                class="col-12 col-md-3 mt-2 mb-2"
                *ngIf="!nenhumRegistro && !loading"
            >
                <button
                    class="btn btn-secondary btn-block mt-4"
                    style="width: 160px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarExcel()"
                >
                    Baixar Excel
                </button>
            </div>

            <table
                class="table table-striped"
                style="max-height: 600px; overflow-y: auto"
            >
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            UF
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Anamneses
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliações Físicas
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Pacientes
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!nenhumRegistro && !loading">
                        <tr
                            *ngFor="let relatorio of relatorioCheckupList?.Resultados; let i = index"
                        >
                            <td>{{relatorio?.NomeAvaliador}}</td>
                            <td>{{relatorio?.UF || ' - '}}</td>
                            <td>{{relatorio?.QtdeAnamneses}}</td>
                            <td>{{relatorio?.QtdeAvaliacoes}}</td>
                            <td>{{relatorio?.QtdePacientes}}</td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loading">
                        <td class="text-center align-middle" colspan="12">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="nenhumRegistro && !loading">
                        <td class="text-center" colspan="12">
                            Nenhum relatório encontrado, por favor insira um
                            filtro.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
