<div class="screen-container">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->

    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h6 class="home-dash mt-5">CADASTRO</h6>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-hash"></div>
        </div>

        <h6
            class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe"
        >
            Usuários
        </h6>

        <div class="container-tabela mt-5">
            <!--   <div class="stepper-wrapper">
                <div class="stepper-item" [ngClass]="{'selected': stepperSelected === stepper.name}"
                 *ngFor="let stepper of steppers" (click)="onSelectStepper(stepper.name)">
                    <div class="step-counter">{{ stepper.name }}</div>
                </div>
            </div> -->

            <div
                *ngIf="stepperSelected === 'Cadastro'"
                class="p-4 scrollable-content"
            >
                <div class="p-4">
                    <div class="row vertical-content">
                        <form class="form-form p-3" [formGroup]="etapa1Data">
                            <div class="col-md-12 row">
                                <div class="col-md-5 mt-2">
                                    <label class="form-label-texts"
                                        >Nome Completo</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Nome"
                                    />
                                </div>
                                <div class="col-md-5 mt-2">
                                    <label class="form-label-texts"
                                        >Login</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Login"
                                    />
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Celular</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="TelefoneCelular"
                                    />
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Telefone</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Telefone"
                                    />
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Email</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Email"
                                    />
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Data de nascimento</label
                                    >
                                    <input
                                        type="date"
                                        class="form-control form-input-box"
                                        formControlName="DataNascimento"
                                    />
                                </div>
                                <div class="col-md-8 mt-5">
                                    <label class="form-label-texts"
                                        >Ocupação atual</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Ocupacao"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts">Sexo</label>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="Sexo"
                                    >
                                        <option
                                            *ngFor="let sexo of opcoesSexo"
                                            [value]="sexo.value"
                                            [selected]="sexo.value === etapa1Data.controls['Sexo'].value"
                                        >
                                            {{ sexo.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Estado civil</label
                                    >
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="EstadoCivil"
                                    >
                                        <option
                                            *ngFor="let estdCivil of opcoesEstadoCivil"
                                            [value]="estdCivil.value"
                                            [selected]="estdCivil.value === etapa1Data.controls['EstadoCivil'].value"
                                        >
                                            {{ estdCivil.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts">Raça</label>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="Raca"
                                    >
                                        <option
                                            *ngFor="let rac of opcoesRaca"
                                            [value]="rac.value"
                                            [selected]="rac.value === etapa1Data.controls['Raca'].value"
                                        >
                                            {{ rac.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Grau de escolaridade</label
                                    >
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="GrauEscolaridade"
                                    >
                                        <option
                                            *ngFor="let grau of grauEscolaridade"
                                            [value]="grau.value"
                                            [selected]="grau.value === etapa1Data.controls['GrauEscolaridade'].value"
                                        >
                                            {{ grau.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <div class="divider-hash"></div>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts">CEP</label>
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="CEP"
                                        (blur)="buscarEnderecoPorCEP()"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Cidade</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Cidade"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts">UF</label>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="UFEndereco"
                                    >
                                        <option selected="selected" value="T">
                                            Todas
                                        </option>
                                        <option value="AC">AC</option>
                                        <option value="AL">AL</option>
                                        <option value="AM">AM</option>
                                        <option value="AP">AP</option>
                                        <option value="BA">BA</option>
                                        <option value="CE">CE</option>
                                        <option value="DF">DF</option>
                                        <option value="ES">ES</option>
                                        <option value="GO">GO</option>
                                        <option value="MA">MA</option>
                                        <option value="MG">MG</option>
                                        <option value="MS">MS</option>
                                        <option value="MT">MT</option>
                                        <option value="PA">PA</option>
                                        <option value="PB">PB</option>
                                        <option value="PE">PE</option>
                                        <option value="PI">PI</option>
                                        <option value="PR">PR</option>
                                        <option value="RJ">RJ</option>
                                        <option value="RN">RN</option>
                                        <option value="RS">RS</option>
                                        <option value="RO">RO</option>
                                        <option value="RR">RR</option>
                                        <option value="SC">SC</option>
                                        <option value="SE">SE</option>
                                        <option value="SP">SP</option>
                                        <option value="TO">TO</option>
                                    </select>
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Bairro</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Bairro"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Logradouro</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Logradouro"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Número</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Numero"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Complemento</label
                                    >
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Complemento"
                                    />
                                </div>
                                <div class="col-md-3 mt-5">
                                    <label class="form-label-texts"
                                        >Correspondência</label
                                    >
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="AceitaCorrespondencia"
                                    >
                                        <option
                                            *ngFor="let correspo of opcoesCorrespondencia"
                                            [value]="correspo.value"
                                            [selected]="correspo.value === etapa1Data.controls['AceitaCorrespondencia'].value"
                                        >
                                            {{ correspo.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <div class="divider-hash"></div>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Perfil</label
                                    >
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="PerfilID"
                                    >
                                        <option
                                            *ngFor="let perf of opcoesPerfil"
                                            [value]="perf.value"
                                            [selected]="perf.value === etapa1Data.controls['PerfilID'].value"
                                        >
                                            {{ perf.label }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts"
                                        >Ativo</label
                                    >
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="Ativo"
                                    >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </select>
                                </div>

                                <div
                                    class="col-md-12 mt-5 d-flex justify-content-end"
                                >
                                    <button
                                        class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                        (click)="onSair()"
                                        [disabled]="loading"
                                    >
                                        Sair
                                    </button>
                                    <button
                                        class="btn btn-primary mr-3 border-radius-10 btn-md"
                                        (click)="onSalvar()"
                                        [disabled]="loading"
                                    >
                                        {{ loading ? 'Salvando...' : 'Salvar' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
