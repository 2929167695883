<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">Relatórios - Pré Cadastro</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="198px"
                    width="230px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="row">
            <div class="col-12 col-md-3">
                <button
                    (click)="atualizarListaPreCadastro()"
                    class="btn-pesquisar btn btn-secondary btn-block mt-4"
                >
                    Atualizar
                </button>
            </div>
        </div>

        <!--tabela-->

        <div
            class="container-tabela mt-5 pt-0 border-top-0"
            style="max-height: 600px; overflow-y: auto"
        >
            <table class="table table-striped border-top-0">
                <thead class="border-top-0">
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            #
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Nome
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            E-mail
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Celular
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Data
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Código Ativação
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!nenhumRegistro && !loading">
                        <tr *ngFor="let relatorio of relatorioPreCadastroList">
                            <td>{{ relatorio?.Usuario?.ID }}</td>
                            <td>{{ relatorio?.Nome }}</td>
                            <td>{{ relatorio?.Email }}</td>
                            <td>{{ relatorio?.Celular }}</td>
                            <td>{{ dateToDDMMYYYY(relatorio?.Cadastro) }}</td>
                            <td>{{ relatorio?.CodigoAtivacao }}</td>
                            <td>{{ relatorio?.Usuario?.Nome }}</td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loading">
                        <td class="text-center align-middle" colspan="8">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="nenhumRegistro && !loading">
                        <td class="text-center" colspan="8">
                            Nenhum pré cadastro encontrado.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>

        <!--tabela-->
    </div>
</div>
