import {getHeaders} from '@/utils/getHeaders';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';

@Injectable({providedIn: 'root'})
export class ProfessorService {
    private api = environment.endpoint_api;

    constructor(private http: HttpClient) {}

    getIdadePacientes() {
        const headers = getHeaders();
        return this.http.get(
            this.api + '/AcessoAdministrador/ConsultarIdadePorGenero',
            {headers}
        );
    }

    getConsultaZPorGenero() {
        const headers = getHeaders();
        return this.http.get(
            this.api + '/AcessoAdministrador/ConsultarZPorGenero',
            {headers}
        );
    }

    getConsultaBiomarcadores() {
        const headers = getHeaders();
        return this.http.get(
            this.api + '/AcessoAdministrador/ObterResultadosBiomarcadores',
            {headers}
        );
    }

    getListaPacientesProfessor(id) {
        const headers = getHeaders();
        return this.http.get(this.api + `/Usuario/ListarClientes/${id}`, {
            headers
        });
    }

    getCupomProfessor() {
        const headers = getHeaders();
        return this.http.get(this.api + '/Cupom/ConsultarMeusCupons', {
            headers
        });
    }

    getAvaliacaoAnamnese(hashAnamnese) {
        const headers = getHeaders();
        return this.http.get(
            this.api + '/Avaliacao/ObterPorHash/' + hashAnamnese,
            {headers}
        );
    }

    //page /acesso-avaliador/usuarios
    postListaUsuariosAvaliador(data: any) {
        const headers = getHeaders();
        return this.http.post(
            this.api + '/Usuario/Consultar',
            {...data},
            {headers}
        );
    }

    //page /acesso-avaliador/meus-clientes
    getListaClientesProfessor() {
        const headers = getHeaders();
        return this.http.get(this.api + `/Usuario/ListarClientes`, {headers});
    }

    //page /acesso-avaliador/usuarios-avaliacoes
    postListaUsuariosAvaliacoes(data: any) {
        const headers = getHeaders();
        return this.http.post(
            this.api + '/Avaliacao/Consultar',
            {...data},
            {headers}
        );
    }

    putDevolverAvaliacao(data: any) {
        const headers = getHeaders();
        return this.http.put(
            this.api + '/Avaliacao/DevolverAvaliacao',
            {...data},
            {headers}
        );
    }

    putEditarAvaliacao(data: any) {
        const headers = getHeaders();
        return this.http.put(this.api + '/Avaliacao', {...data}, {headers});
    }

    finalizarAvaliacao(data: any) {
        const headers = getHeaders();
        return this.http.put(
            this.api + '/Avaliacao/FinalizarAvaliacao/',
            {...data},
            {headers}
        );
    }

    postEnviarSenha(data: any) {
        const headers = getHeaders();
        return this.http.post(
            this.api + '/Usuario/ReenviarSenha',
            {...data},
            {headers}
        );
    }

    comecarAnamnese(hash: string) {
        const headers = getHeaders();

        const body = {
            Hash: hash
        };
        return this.http.put(this.api + '/Anamnese/ComecarAnamnese', body, {
            headers
        });
    }

    postIncluirUsuario(data: any) {
        const headers = getHeaders();
        return this.http.post(this.api + '/Usuario', {...data}, {headers});
    }

    postAtualizarDadosCadastrais(data: any) {
        const headers = getHeaders();
        return this.http.post(
            this.api + '/Usuario/AtualizarDadosCadastrais',
            {...data},
            {headers}
        );
    }

    putEditarUsuario(data: any) {
        const headers = getHeaders();
        return this.http.put(this.api + '/Usuario', {...data}, {headers});
    }

    getConsultarEvolucaoPorGenero() {
        const headers = getHeaders();
        return this.http.get(
            `${this.api}/AcessoAdministrador/ConsultarEvolucaoPorGenero`,
            {headers}
        );
    }

    getObterListaAvaliadores() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Usuario/ObterListaAvaliadores`, {
            headers
        });
    }

    getObterListaClientesSelect() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Usuario/ObterListaClientes`, {
            headers
        });
    }

    getObterListaClientesGrupoSelect() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Grupo/ListarAtivos`, {headers});
    }

    getObterCheckupsUsuario(IDUsuario: string) {
        const headers = getHeaders();
        return this.http.get(
            `${this.api}/Usuario/CheckupsUsuario/${IDUsuario}`,
            {headers}
        );
    }

    getObterListaClientes(IDUsuario: string) {
        const headers = getHeaders();
        return this.http.get(
            `${this.api}/Usuario/listarClientes/${IDUsuario}`,
            {headers}
        );
    }

    getListaPlanosAtivos() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Plano/ListarAtivos`, {headers});
    }

    getObterDadosCadastro(IDUsuario: string) {
        const headers = getHeaders();
        return this.http.get(
            `${this.api}/Usuario/ObterDadosCadastrais/${IDUsuario}`,
            {headers}
        );
    }

    postRemoverClienteAvaliador(data: any) {
        const headers = getHeaders();
        return this.http.post(
            `${this.api}/Usuario/RemoverClienteAvaliador`,
            {...data},
            {headers}
        );
    }

    postNovoCheckup(data: any) {
        const headers = getHeaders();
        return this.http.post(`${this.api}/Checkup`, {...data}, {headers});
    }

    getListaSenhasProvisorias() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Usuario/ListarSenhasProvisorias`, {
            headers
        });
    }

    //post /Checkup/Consultar
    postConsultarCheckup(data: any) {
        const headers = getHeaders();
        return this.http.post(
            `${this.api}/Checkup/Consultar`,
            {...data},
            {headers}
        );
    }

    getObterCheckup(hash: string) {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Checkup/ObterPorHash/${hash}`, {
            headers
        });
    }

    postIncluirCheckup(data: any) {
        const headers = getHeaders();
        return this.http.post(`${this.api}/Checkup`, {...data}, {headers});
    }

    putIncluirCheckup(data: any) {
        const headers = getHeaders();
        return this.http.put(`${this.api}/Checkup`, {...data}, {headers});
    }

    //redefinir-senha
    postRedefinirSenha(data: any) {
        const headers = getHeaders();
        return this.http.post(
            `${this.api}/Usuario/RedefinirSenha`,
            {...data},
            {headers}
        );
    }
    //alterar senha aluno
    postAlterarSenha(data: any) {
        const headers = getHeaders();
        return this.http.post(
            `${this.api}/Usuario/AlterarSenha`,
            {...data},
            {headers}
        );
    }

    //get ObterCheckupsCliente
    getObterCheckupsCliente(id: string) {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Usuario/ObterCheckupsCliente/${id}`, {
            headers
        });
    }

    //get /ListarUsuariosOnlines
    getListarUsuariosOnlines() {
        const headers = getHeaders();
        return this.http.get(`${this.api}/Usuario/ListarUsuariosOnlines`, {
            headers
        });
    }

    //post generate-url profile pic
    generateUrlProfilePic(idUsuario: number | string, filename: string) {
        const headers = getHeaders();
        const body = {filename};

        return this.http.post(
            `${this.api}/Usuario/generate-url/${idUsuario}`,
            body,
            {headers}
        );
    }

    //put guardaFotoS3
    guardaFotoS3(url: string, file: File, fileType: string) {
        // fileType: 'image/jpeg'
        return this.http.put(url, file, {
            headers: {
                'Content-Type': fileType
            }
        });
    }
}
