<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">Relatório Auto-Consciência</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="atualizarListagemRelatorio()"
            [formGroup]="formAutoConsciencia"
            class="container-tabela p-4"
        >
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar Relatório
            </h6>

            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Avaliador</span
                        >

                        <select
                            [value]="
                                formAutoConsciencia.get('AvaliadorID').value
                            "
                            class="form-control form-input-select"
                            formControlName="AvaliadorID"
                        >
                            <option value="0">Selecione</option>
                            <option
                                *ngFor="let avaliador of listaAvaliadores"
                                [value]="avaliador.ID"
                            >
                                {{ avaliador.Nome }}
                            </option>
                        </select>
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Inicio</span
                        >

                        <input
                            class="form-input-box-date form-control"
                            formControlName="PeriodoDe"
                            id="PeriodoDe"
                            type="date"
                        />
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Fim</span>

                        <input
                            class="form-input-box-date form-control"
                            formControlName="PeriodoAte"
                            id="PeriodoAte"
                            type="date"
                        />
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="submit"
                            [disabled]="loading"
                        >
                            {{ loading ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="container-tabela mt-5">
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                Relatórios
            </h2>
            <ng-container *ngIf="!nenhumRegistro && !loading">
                <div
                    *ngFor="
                        let usuarioRelatorios of relatorioAutoConscienciaList
                    "
                    style="
                        max-height: 600px;
                        overflow-y: auto;
                        margin-top: 20px;
                        margin-bottom: 20px;
                        padding: 20px;
                        border: 1px solid #f0f0f0;
                        border-radius: 15px;
                    "
                >
                    <table class="table table-striped">
                        <thead>
                            <tr>
                                <th
                                    class="th-tabela text-azul-claro-plataforma-safe"
                                >
                                    Data
                                </th>
                                <th
                                    class="th-tabela text-azul-claro-plataforma-safe"
                                >
                                    Nome
                                </th>
                                <th
                                    class="th-tabela text-azul-claro-plataforma-safe"
                                >
                                    Questão
                                </th>
                                <th
                                    class="th-tabela text-azul-claro-plataforma-safe"
                                >
                                    Resultado
                                </th>
                            </tr>
                        </thead>

                        <tbody id="listaPacientes" #target>
                            <tr
                                *ngFor="
                                    let resultado of usuarioRelatorios?.Resultados
                                "
                            >
                                <td>
                                    {{
                                        dateToDDMMYYYY(
                                            usuarioRelatorios.Atualizacao
                                        )
                                    }}
                                </td>
                                <td>{{ usuarioRelatorios?.Usuario?.Nome }}</td>
                                <td>
                                    {{
                                        resultado?.Biomarcador ||
                                            'Sem Informação'
                                    }}
                                </td>
                                <td>{{ resultado?.Resultado }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ng-container>
            <div *ngIf="loading" class="text-center w-100 mt-5">
                <div
                    class="spinner-border text-azul-plataforma-safe"
                    role="status"
                >
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div
                *ngIf="!loading && nenhumRegistro"
                class="text-center w-100 mt-5"
            >
                <p>Nenhum relatório encontrado, por favor insira um filtro.</p>
            </div>
        </div>
    </div>
</div>
