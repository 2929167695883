import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {LoginModel} from '@/models/login.model';

@Component({
    selector: 'app-login-page',
    templateUrl: './login-page.component.html',
    styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
    public formLogin: FormGroup;
    public carregandoLogin: boolean = false;
    public mostrarSenha: boolean = false; // Para alternar visibilidade da senha
    public router = window;

    constructor(
        private toastr: ToastrService,
        private appService: AppService
    ) {}

    ngOnInit() {
        const login = new LoginModel();
        this.formLogin = new FormGroup({
            email: new FormControl(login.email, [Validators.required]),
            senha: new FormControl(login.senha, [Validators.required])
        });
    }

    toggleMostrarSenha() {
        this.mostrarSenha = !this.mostrarSenha;
    }

    async onClickLogin() {
        try {
            this.carregandoLogin = true;
            if (this.formLogin.valid) {
                await this.appService.loginByAuth(this.formLogin.value);
                this.carregandoLogin = false;
            } else {
                this.toastr.error(
                    'Preencha os campos do formulário corretamente',
                    'Campos inválidos'
                );
                this.carregandoLogin = false;
            }
        } catch (error) {
            if (error?.error?.error_description.length < 210) {
                this.toastr.error(error?.error?.error_description);
            } else {
                this.toastr.error('Erro ao realizar login, contate o suporte');
            }
            this.carregandoLogin = false;
        }
        this.carregandoLogin = false; // Defina como false, independentemente do resultado
    }

    async handleCadastrarClick() {
        this.router.location.href = '';
    }
}
