import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalInfoSobreSaudeComponent} from '../modal3-InfoSobreSaude/modal-infoSobreSaude.component';
import {ModalCheckBoxesComponent} from '@components/dashboard/modais/Modal5-CheckBoxes/modal-checkBoxes.component';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-modal-questFarmPrelim',
    templateUrl: './modal-questFarmPrelim.component.html',
    styleUrls: ['./modal-questFarmPrelim.component.scss']
})
export class ModalQuestFarmPrelimComponent implements OnInit {
    public erroAvancarAnamnese: string | any = '';
    public campoinvalido: string | any = '';
    public carregandoAtualizarAnamnese: boolean = false;
    public formGroupEtapa4: FormGroup;
    public desativarCampos: boolean = false;

    public opcoesFrequencia: Array<number> = [1, 2, 3, 4, 5, 6, 7];
    public opcoesCheckboxSimOuNao = [
        {
            label: 'Sim',
            value: true
        },
        {
            label: 'Não',
            value: false
        }
    ];
    public opcoesCheckboxSimOuNao2 = [
        {
            label: 'Sim',
            value: true
        },
        {
            label: 'Não',
            value: false
        }
    ];
    public opcoesIntensidade = [
        {
            label: 'Baixa',
            value: 1
        },
        {
            label: 'Média',
            value: 2
        },
        {
            label: 'Alta',
            value: 3
        }
    ];

    public opcoesMedicamentos = [];
    public listaMedicamentos: FormArray;
    public loadingBuscaMed: boolean = false;

    // Campos de estado para adicionar medicamentos

    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador');
        const anamnese = this.anamneseService.getAnamnese();

        this.formGroupEtapa4 = new FormGroup({
            NumeroEtapa: new FormControl(4),
            AnamneseID: new FormControl(anamnese?.Etapa4?.AnamneseID),
            AlergiaMedicamentos: new FormControl({
                value: anamnese?.Etapa4?.AlergiaMedicamentos,
                disabled: this.desativarCampos
            }),
            MedicamentosAlergenicos: new FormControl({
                value: anamnese?.Etapa4?.MedicamentosAlergenicos,
                disabled: this.desativarCampos
            }),
            AlergiaOutros: new FormControl({
                value: anamnese?.Etapa4?.AlergiaOutros,
                disabled: this.desativarCampos
            }),
            Produtos: new FormControl({
                value: anamnese?.Etapa4?.Produtos,
                disabled: this.desativarCampos
            }),
            FrequenciaHorarioMedicamentos: new FormControl({
                value: anamnese?.Etapa4?.FrequenciaHorarioMedicamentos,
                disabled: this.desativarCampos
            }),
            ExercicioRegular: new FormControl({
                value: anamnese?.Etapa4?.ExercicioRegular,
                disabled: this.desativarCampos
            }),
            Caminhada: new FormControl({
                value: anamnese?.Etapa4?.Caminhada,
                disabled: this.desativarCampos
            }),
            CaminhadaFrequencia: new FormControl({
                value: anamnese?.Etapa4?.CaminhadaFrequencia,
                disabled: this.desativarCampos
            }),
            CaminhadaIntensidade: new FormControl({
                value: anamnese?.Etapa4?.CaminhadaIntensidade,
                disabled: this.desativarCampos
            }),
            CaminhadaDuracao: new FormControl({
                value: anamnese?.Etapa4?.CaminhadaDuracao,
                disabled: this.desativarCampos
            }),
            Corrida: new FormControl({
                value: anamnese?.Etapa4?.Corrida,
                disabled: this.desativarCampos
            }),
            CorridaFrequencia: new FormControl({
                value: anamnese?.Etapa4?.CorridaFrequencia,
                disabled: this.desativarCampos
            }),
            CorridaIntensidade: new FormControl({
                value: anamnese?.Etapa4?.CorridaIntensidade,
                disabled: this.desativarCampos
            }),
            CorridaDuracao: new FormControl({
                value: anamnese?.Etapa4?.CorridaDuracao,
                disabled: this.desativarCampos
            }),
            Musculacao: new FormControl({
                value: anamnese?.Etapa4?.Musculacao,
                disabled: this.desativarCampos
            }),
            MusculacaoFrequencia: new FormControl({
                value: anamnese?.Etapa4?.MusculacaoFrequencia,
                disabled: this.desativarCampos
            }),
            MusculacaoIntensidade: new FormControl({
                value: anamnese?.Etapa4?.MusculacaoIntensidade,
                disabled: this.desativarCampos
            }),
            MusculacaoDuracao: new FormControl({
                value: anamnese?.Etapa4?.MusculacaoDuracao,
                disabled: this.desativarCampos
            }),
            Danca: new FormControl({
                value: anamnese?.Etapa4?.Danca,
                disabled: this.desativarCampos
            }),
            DancaFrequencia: new FormControl({
                value: anamnese?.Etapa4?.DancaFrequencia,
                disabled: this.desativarCampos
            }),
            DancaIntensidade: new FormControl({
                value: anamnese?.Etapa4?.DancaIntensidade,
                disabled: this.desativarCampos
            }),
            DancaDuracao: new FormControl({
                value: anamnese?.Etapa4?.DancaDuracao,
                disabled: this.desativarCampos
            }),
            Hidroginastica: new FormControl({
                value: anamnese?.Etapa4?.Hidroginastica,
                disabled: this.desativarCampos
            }),
            HidroginasticaFrequencia: new FormControl({
                value: anamnese?.Etapa4?.HidroginasticaFrequencia,
                disabled: this.desativarCampos
            }),
            HidroginasticaIntensidade: new FormControl({
                value: anamnese?.Etapa4?.HidroginasticaIntensidade,
                disabled: this.desativarCampos
            }),
            HidroginasticaDuracao: new FormControl({
                value: anamnese?.Etapa4?.HidroginasticaDuracao,
                disabled: this.desativarCampos
            }),
            OutroExercicio: new FormControl({
                value: anamnese?.Etapa4?.OutroExercicio,
                disabled: this.desativarCampos
            }),
            OutroExercicioFrequencia: new FormControl({
                value: anamnese?.Etapa4?.OutroExercicioFrequencia,
                disabled: this.desativarCampos
            }),
            OutroExercicioIntensidade: new FormControl({
                value: anamnese?.Etapa4?.OutroExercicioIntensidade,
                disabled: this.desativarCampos
            }),
            OutroExercicioDuracao: new FormControl({
                value: anamnese?.Etapa4?.OutroExercicioDuracao,
                disabled: this.desativarCampos
            }),
            DescOutroExercicio: new FormControl({
                value: anamnese?.Etapa4?.DescOutroExercicio,
                disabled: this.desativarCampos
            }),
            QtdExercicioSemana: new FormControl({
                value: anamnese?.Etapa4?.QtdExercicioSemana,
                disabled: this.desativarCampos
            }),
            Intensidade: new FormControl({
                value: anamnese?.Etapa4?.Intensidade,
                disabled: this.desativarCampos
            }),
            DuracaoSessao: new FormControl({
                value: anamnese?.Etapa4?.DuracaoSessao,
                disabled: this.desativarCampos
            }),
            SomaSemanal: new FormControl({
                value: anamnese?.Etapa4?.SomaSemanal,
                disabled: this.desativarCampos
            }),
            SuplementoAlimentar: new FormControl({
                value: anamnese?.Etapa4?.SuplementoAlimentar,
                disabled: this.desativarCampos
            }),
            DescSuplemento: new FormControl({
                value: anamnese?.Etapa4?.DescSuplemento,
                disabled: this.desativarCampos
            }),
            MedicamentosEmUso: new FormControl({
                value: anamnese?.Etapa4?.MedicamentosEmUso,
                disabled: this.desativarCampos
            }),
            MedicamentosEmUsoSIM: new FormControl({
                value: anamnese?.Etapa4?.MedicamentosEmUsoSIM,
                disabled: this.desativarCampos
            }),
            DataInicio: new FormControl({
                value: new Date(),
                disabled: this.desativarCampos
            }),
            DataFim: new FormControl(null),
            CafeDaManha: new FormControl({
                value: anamnese?.Etapa4?.CafeDaManha,
                disabled: this.desativarCampos
            }),
            Lanche1: new FormControl({
                value: anamnese?.Etapa4?.Lanche1,
                disabled: this.desativarCampos
            }),
            Almoco: new FormControl({
                value: anamnese?.Etapa4?.Almoco,
                disabled: this.desativarCampos
            }),
            Lanche2: new FormControl({
                value: anamnese?.Etapa4?.Lanche2,
                disabled: this.desativarCampos
            }),
            Jantar: new FormControl({
                value: anamnese?.Etapa4?.Jantar,
                disabled: this.desativarCampos
            }),
            Ceia: new FormControl({
                value: anamnese?.Etapa4?.Ceia,
                disabled: this.desativarCampos
            }),
            listaMedicamentos: new FormArray([]),
            MedicamentoId: new FormControl({
                value: '',
                disabled: this.desativarCampos
            }),
            Nome: new FormControl({value: '', disabled: this.desativarCampos}), // { value: '', disabled: this.desativarCampos
            Dose: new FormControl({value: '', disabled: this.desativarCampos}), // { value: '', disabled: this.desativarCampos
            Periodicidade: new FormControl({
                value: '',
                disabled: this.desativarCampos
            }) // { value: '', disabled: this.desativarCampos
        });

        // Busca medicamento e guarda getBuscarMedicamentos
        this.loadingBuscaMed = true;
        this.anamneseService.getBuscarMedicamentos().subscribe({
            next: (response: any) => {
                this.loadingBuscaMed = false;
                this.opcoesMedicamentos = response;
            },
            error: (error) => {
                this.loadingBuscaMed = false;
            }
        });

        // Inicializa lista de medicamentos como um FormArray
        this.listaMedicamentos = this.formGroupEtapa4.get(
            'listaMedicamentos'
        ) as FormArray;

        this.addMedicamentosToForm(anamnese?.Etapa4?.MedicamentosEmUso);
    }

    addMedicamentosToForm(medicamentos: any[]) {
        if (!medicamentos || medicamentos.length === 0) {
            this.formGroupEtapa4.get('MedicamentosEmUsoSIM').setValue(false);
            console.log(
                'medicamento não encontrado',
                this.formGroupEtapa4.get('MedicamentosEmUsoSIM').value
            );
            return;
        }
        console.log('medicamento encontrado', medicamentos);
        medicamentos.forEach((med) => {
            this.formGroupEtapa4.get('MedicamentosEmUsoSIM').setValue(true);

            const medicamentoGroup = new FormGroup({
                Produto: new FormControl({
                    value: med.Medicamento.Produto.Produto,
                    disabled: true
                }),
                Nome: new FormControl({value: med.Nome, disabled: true}),
                Dose: new FormControl({value: med.Dose, disabled: true}),
                Periodicidade: new FormControl({
                    value: med.Periodicidade,
                    disabled: true
                }),
                AnamneseID: new FormControl(
                    this.formGroupEtapa4.get('AnamneseID')?.value
                ),
                Finalidade: new FormControl({value: '', disabled: true}),
                ID: new FormControl(Math.random() * 10000000),
                Horario: new FormControl({value: new Date(), disabled: true}),
                Medicamento: new FormControl({
                    value: med.Medicamento.Produto.Produto,
                    disabled: true
                }),
                MedicamentoId: new FormControl({
                    value: med.Medicamento.Produto.ID,
                    disabled: true
                })
            });
            this.listaMedicamentos.push(medicamentoGroup);
        });
    }

    handleClickFecharModal() {
        this.appService.closeModal();
    }

    handleClickVoltarModal() {
        this.appService.closeModal();
        this.appService.openModal(ModalInfoSobreSaudeComponent);
    }

    handleAdicionarMedicamento() {
        const medID = this.formGroupEtapa4?.get('MedicamentoId')?.value;
        const medNome = this.formGroupEtapa4?.get('Nome')?.value;
        const medDose = this.formGroupEtapa4?.get('Dose')?.value;
        const medPeriodicidade =
            this.formGroupEtapa4?.get('Periodicidade')?.value;

        if (!medID || !medNome || !medDose || !medPeriodicidade) {
            this.toastr.warning(
                'Preencha todos os campos para adicionar um medicamento',
                'Erro ao adicionar medicamento'
            );

            return;
        }

        //busca no array de medicamentos o medicamento selecionado
        const medProduto = this.opcoesMedicamentos.find(
            (med) => med.ID === medID
        ).Produto;
        const medicamentoForm = new FormGroup({
            AnamneseID: new FormControl(
                this.formGroupEtapa4.get('AnamneseID')?.value
            ),
            Dose: new FormControl({value: medDose, disabled: true}),
            Finalidade: new FormControl({value: '', disabled: true}),
            ID: new FormControl(Math.random() * 10000000),
            Horario: new FormControl({value: new Date(), disabled: true}),
            Produto: new FormControl({value: medProduto, disabled: true}),
            Medicamento: new FormControl({value: medProduto, disabled: true}),
            MedicamentoId: new FormControl({value: medID, disabled: true}),
            Nome: new FormControl({value: medNome, disabled: true}),
            Periodicidade: new FormControl({
                value: Number(medPeriodicidade),
                disabled: true
            })
        });

        this.listaMedicamentos.push(medicamentoForm);

        // Reseta os campos de entrada
        this.formGroupEtapa4.get('MedicamentoId')?.reset();
        this.formGroupEtapa4.get('Nome')?.reset();
        this.formGroupEtapa4.get('Dose')?.reset();
        this.formGroupEtapa4.get('Periodicidade')?.reset();
    }

    handleRemoverMedicamento(index: number) {
        this.listaMedicamentos.removeAt(index);
    }

    handleClickProximoModal() {
        // Caso os campos estejam desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informações
        // Logo, a gente não atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal();
            this.appService.openModal(ModalCheckBoxesComponent);
            return;
        }

        const anamnese = this.anamneseService.getAnamnese();

        this.carregandoAtualizarAnamnese = true;

        const novaAnamneseAtualizada = {
            ...anamnese,
            Etapa4: {
                ...anamnese?.Etapa4,
                ...this.formGroupEtapa4.getRawValue(),
                DataFim: new Date(),
                MedicamentosEmUso: this.listaMedicamentos.getRawValue()
            }
        };

        // Primeiro, validamos a anamnese para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next: (response) => {
                // Verifico se há etapas inválidas (endpoint retorna 200 mesmo para dados inválidos)
                if (
                    // @ts-ignore
                    typeof response?.etapaInvalida?.mensagem == 'string' &&
                    // @ts-ignore
                    response?.etapaInvalida?.etapa === 4
                ) {
                    // @ts-ignore
                    this.erroAvancarAnamnese =
                        // @ts-ignore
                        response?.etapaInvalida?.mensagem;
                    // @ts-ignore
                    this.campoinvalido = response?.etapaInvalida?.campo;
                    this.carregandoAtualizarAnamnese = false;
                    return;
                }

                // Atualiza a anamnese se tudo estiver ok
                this.anamneseService
                    .putAnamnese(novaAnamneseAtualizada)
                    .subscribe({
                        next: (response2) => {
                            this.carregandoAtualizarAnamnese = false;
                            this.anamneseService.setAnamnese(response2);
                            this.appService.closeModal();
                            this.appService.openModal(ModalCheckBoxesComponent);
                        },
                        error: (response) => {
                            this.carregandoAtualizarAnamnese = false;
                            if (typeof response?.error?.Message == 'string') {
                                this.erroAvancarAnamnese =
                                    response?.error?.Message;
                            } else {
                                this.erroAvancarAnamnese =
                                    'Ocorreu um erro ao atualizar a anamnese';
                            }
                        }
                    });
            },
            error: (erro) => {
                this.carregandoAtualizarAnamnese = false;
                if (typeof erro?.Message == 'string') {
                    this.erroAvancarAnamnese = erro?.Message;
                } else {
                    this.erroAvancarAnamnese =
                        'Ocorreu um erro ao validar as informações preenchidas';
                }
            }
        });
    }
}
