import {HttpHeaders} from '@angular/common/http';

export const getHeaders = (data: object = {}): HttpHeaders => {
    const accessToken = localStorage.getItem('token');
    // Set up the headers with the Bearer token

    if (accessToken) {
        return new HttpHeaders({
            Authorization: `Bearer ${accessToken}`,
            ...data
        });
    } else {
        return new HttpHeaders({
            ...data
        });
    }
};
