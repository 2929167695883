<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5 text-amarelo-plataforma-safe">
                    CHECK-UPS
                </h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="container-tabela">
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar CHECK-UPS
            </h6>

            <div class="col-12 mt-6">
                <div class="pesquisa-tabela row">
                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Cliente</span
                        >

                        <input
                            [(ngModel)]="dataPesquisa.Cliente"
                            class="form-input-box form-control"
                            id="Cliente"
                            name="Cliente"
                            type="input"
                        />
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Avaliador</span
                        >

                        <input
                            [(ngModel)]="dataPesquisa.Avaliador"
                            class="form-input-box form-control"
                            id="Avaliador"
                            name="Avaliador"
                            type="input"
                        />
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Protocolo</span
                        >

                        <input
                            [(ngModel)]="dataPesquisa.Protocolo"
                            class="form-input-box form-control"
                            id="Protocolo"
                            name="Protocolo"
                            type="input"
                        />
                    </div>

                    <div class="col-2 mt-2"></div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Data de cadastro</span
                        >
                        <input
                            [(ngModel)]="dataPesquisa.DataCadastro"
                            class="form-input-box form-control"
                            id="DataCadastro"
                            name="DataCadastro"
                            type="date"
                        />
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Situação</span
                        >
                        <select
                            [(ngModel)]="dataPesquisa.Situacao"
                            class="form-control form-input-select"
                            id="Situacao"
                            placeholder="Selecione"
                        >
                            <option
                                *ngFor="let option of opcoesSituacao"
                                [ngValue]="option.value"
                            >
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>
                    <div class="col-9"></div>

                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="button"
                            (click)="getListaPacientes()"
                            [disabled]="loadingListaPacientes"
                        >
                            {{
                                loadingListaPacientes
                                    ? 'Carregando...'
                                    : 'Pesquisar'
                            }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-5">
            <h6 class="titulo-usuarios text-cinza-claro-plataforma-safe">
                Ações
            </h6>
            <span class="text-help text-cinza-claro-plataforma-safe"
                >Para ativar alguma ação, selecione algum paciente.</span
            >
            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="button"
                            (click)="novoCheckup()"
                        >
                            Novo
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            (click)="alterarCheckup()"
                            [disabled]="
                                !pacientesSelecionados ||
                                pacientesSelecionados.length === 0 ||
                                pacientesSelecionados.length > 1
                            "
                            type="button"
                        >
                            Alterar
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            disabled
                            type="button"
                        >
                            Gerar Todos CHECK-UPS
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                CHECK-UPS Cadastrados
            </h2>

            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            <input
                                (change)="selectAll($event)"
                                class="form-checkbox-input"
                                id="selectAll"
                                type="checkbox"
                            />
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Protocolo
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Cliente
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Situação
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Data de Cadastro
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngIf="
                            nenhumUsuarioEncontrado && !loadingListaPacientes
                        "
                    >
                        <td class="text-center" colspan="8">
                            Nenhum Checkup encontrado, por favor insira um
                            filtro.
                        </td>
                    </tr>
                    <ng-container
                        *ngIf="
                            !nenhumUsuarioEncontrado && !loadingListaPacientes
                        "
                    >
                        <tr *ngFor="let paciente of listaPacientes">
                            <td>
                                <input
                                    (change)="
                                        selecionarPaciente(
                                            paciente.IdentificadorHash
                                        )
                                    "
                                    [value]="paciente.IdentificadorHash"
                                    class="form-checkbox-input"
                                    type="checkbox"
                                />
                            </td>
                            <td>{{ paciente.Protocolo }}</td>
                            <td>{{ paciente.Avaliador }}</td>
                            <td>{{ paciente.Cliente }}</td>
                            <td>
                                {{
                                    paciente.Situacao === 0
                                        ? 'Em andamento'
                                        : paciente.Situacao === 1
                                        ? 'Finalizado'
                                        : paciente.Situacao === 2
                                        ? 'Cancelado'
                                        : 'Bloqueado'
                                }}
                            </td>
                            <td>{{ formatDate(paciente.Data) }}</td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loadingListaPacientes">
                        <td class="text-center align-middle" colspan="8">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
