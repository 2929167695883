<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div class="w-100 d-flex justify-content-between">
            <h6></h6>
            <img
                src="assets/img/logoSafeTextoBranco.png"
                alt="plat-safe-logo"
                height="168px"
                width="200px"
            />
        </div>

        <h6
            class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe"
        >
            Meus CHECK-UPS
        </h6>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            CHECK UP Virtual
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliação Física
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Z
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Laudo
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        *ngIf="nenhumUsuarioEncontrado && !loadingListaPacientes"
                    >
                        <td class="text-center" colspan="8">
                            Nenhum Checkup encontrado.
                        </td>
                    </tr>
                    <ng-container
                        *ngIf="!nenhumUsuarioEncontrado && !loadingListaPacientes"
                    >
                        <tr *ngFor="let paciente of listaPacientes">
                            <td>{{ paciente.Avaliador }}</td>
                            <td>
                                {{ paciente.DataCheckup ?
                                formatDate(paciente.DataCheckup) : 'Não
                                realizado' }}
                            </td>
                            <td>
                                {{ paciente.DataAvaliacao ?
                                formatDate(paciente.DataAvaliacao) : 'Não
                                realizado' }}
                            </td>
                            <td>{{ paciente.Z }}</td>
                            <td>
                                <button
                                    class="btn btn-sm btn-primary"
                                    (click)="abrirLaudo(paciente?.HashAnamnese)"
                                >
                                    Baixar
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loadingListaPacientes">
                        <td class="text-center align-middle" colspan="8">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
