<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">
                    Relatório de Aniversariantes do Mês
                </h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="atualizarListagemRelatorio()"
            [formGroup]="formCadastro"
            class="container-tabela p-4"
        >
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar Relatórios
            </h6>
            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Mês</span>
                        <select
                            [value]="formCadastro.get('mesID').value"
                            class="form-control form-input-select"
                            formControlName="mesID"
                        >
                            <option value="0">Selecione</option>
                            <option
                                *ngFor="let mes of listaMeses"
                                [value]="mes.id"
                            >
                                {{ mes.nome }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 col-12 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="submit"
                            [disabled]="loading"
                        >
                            {{ loading ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                Relatórios: {{ relatorioList?.length }}
            </h2>
            <table
                class="table table-striped"
                style="max-height: 600px; overflow-y: auto"
            >
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            #
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Nome
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Data de Nascimento
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Idade
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            E-mail
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Celular
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Data de Cadastro
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            CHECK-UPS
                        </th>
                        <th
                            class="th-tabela text-azul-claro-plataforma-safe"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!nenhumRegistro && !loading">
                        <tr
                            *ngFor="
                                let relatorio of relatorioList;
                                let i = index
                            "
                        >
                            <td>{{ i + 1 }}</td>
                            <td>{{ relatorio?.Nome }}</td>
                            <td>{{ relatorio?.DataNascimento }}</td>
                            <td>{{ relatorio?.Idade }}</td>
                            <td
                                style="
                                    max-width: 230px;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                "
                            >
                                {{ relatorio?.Email }}
                            </td>
                            <td style="white-space: nowrap">
                                {{ formatarCelular(relatorio?.Celular) }}
                            </td>
                            <td>{{ relatorio?.Data }}</td>
                            <td>{{ relatorio?.Checkups }}</td>
                            <td style="white-space: nowrap">
                                <a
                                    class="button-usuarios btn btn-secondary btn-block size-sm w-130"
                                    target="_blank"
                                    href="https://api.whatsapp.com/send?phone={{
                                        relatorio?.Celular
                                    }}&text=Olá {{
                                        relatorio?.Nome
                                    }}, tudo bem? Vi que você está de aniversário, parabéns!, desejo a você muitas feliciades e acima de tudo saúde e bem estar físico e mental."
                                >
                                    Enviar Mensagem
                                </a>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loading">
                        <td class="text-center align-middle" colspan="12">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="nenhumRegistro && !loading">
                        <td class="text-center" colspan="12">
                            Nenhum relatório encontrado, por favor insira um
                            filtro.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
