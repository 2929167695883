<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">
                    Relatório de CHECK-UPS SAFE® por Período
                </h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="atualizarListagemRelatorio()"
            [formGroup]="formCadastro"
            class="container-tabela p-4"
        >
            <h6
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3"
            >
                Filtrar Relatórios
            </h6>

            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe"
                            >Avaliador</span
                        >
                        <ng-select
                            [items]="listaAvaliadores"
                            bindLabel="Nome"
                            bindValue="ID"
                            class="form-input-box-select"
                            formControlName="AvaliadorID"
                            [virtualScroll]="true"
                            [searchable]="true"
                        >
                        </ng-select>
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Periodo De
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="PeriodoDe"
                            formControlName="PeriodoDe"
                            type="date"
                        />
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">
                            Periodo Até
                        </span>

                        <input
                            class="form-input-box-date form-control"
                            id="PeriodoAte"
                            formControlName="PeriodoAte"
                            type="date"
                        />
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="submit"
                            [disabled]="loading"
                        >
                            {{ loading ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div
            class="container-tabela mt-5"
            style="max-height: 600px; overflow-y: auto"
        >
            <h2
                class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe"
            >
                Relatórios: {{ relatorioCheckupPeriodoList?.length }}
            </h2>
            <div class="col-12 col-md-3 mt-2">
                <button
                    class="btn btn-secondary btn-block mt-4"
                    *ngIf="relatorioCheckupPeriodoList?.length > 0"
                    style="width: 110px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarPDF()"
                >
                    Baixar PDF
                </button>
            </div>
            <div class="col-12 col-md-3 mt-2 mb-2">
                <button
                    class="btn btn-secondary btn-block mt-4"
                    *ngIf="relatorioCheckupPeriodoList?.length > 0"
                    style="width: 160px; height: 35px; margin-left: 5px"
                    type="button"
                    (click)="baixarExcel()"
                >
                    Baixar Excel
                </button>
            </div>

            <table
                class="table table-striped"
                style="max-height: 600px; overflow-y: auto"
            >
                <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            #
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Avaliador
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Cliente
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Cadastro
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Início
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Término
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            Teste físico
                        </th>
                        <th
                            class="th-tabela text-azul-claro-plataforma-safe"
                        ></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngIf="!nenhumRegistro && !loading">
                        <tr
                            *ngFor="
                                let relatorio of relatorioCheckupPeriodoList;
                                let i = index
                            "
                        >
                            <td>{{ i + 1 }}</td>
                            <td>{{ relatorio?.Avaliador }}</td>
                            <td>{{ relatorio?.Cliente }}</td>
                            <td>
                                {{
                                    dateToDDMMYYYY(relatorio?.Cadastro) ||
                                        'Sem informação'
                                }}
                            </td>
                            <td>
                                {{
                                    dateToDDMMYYYY(relatorio?.InicioCheckup) ||
                                        'Sem informação'
                                }}
                            </td>
                            <td>
                                {{
                                    dateToDDMMYYYY(relatorio?.EnvioCheckup) ||
                                        'Sem informação'
                                }}
                            </td>
                            <td>
                                {{
                                    dateToDDMMYYYY(relatorio?.Avaliacao) ||
                                        'Sem informação'
                                }}
                            </td>
                            <td>
                                <button
                                    class="btn btn-secondary"
                                    (click)="
                                        baixarLaudo(relatorio?.HashAnamnese)
                                    "
                                >
                                    Laudo
                                </button>
                            </td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loading">
                        <td class="text-center align-middle" colspan="12">
                            <div
                                class="spinner-border text-azul-plataforma-safe"
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    <tr *ngIf="nenhumRegistro && !loading">
                        <td class="text-center" colspan="12">
                            Nenhum relatório encontrado, por favor insira um
                            filtro.
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
