import {DateTime} from 'luxon';
import {Component} from '@angular/core';
import {ProfessorService} from '@services/dashboard/professor/professor.service';
import {ToastrService} from 'ngx-toastr';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';

@Component({
    selector: 'app-redefinir-senha',
    templateUrl: './redefinir-senha.component.html',
    styleUrls: ['./redefinir-senha.component.scss']
})
export class RedefinirSenhaComponent {
    public loadingRecover: boolean = false;
    public formRecover: FormGroup;
    public mostrarSenha: boolean = false;
    public mostrarConfirmarSenha: boolean = false;

    constructor(
        private professorService: ProfessorService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        let localUser = localStorage.getItem('user');
        let user = JSON.parse(localUser);
        let id = user?.ID;

        this.formRecover = new FormGroup({
            SenhaNova: new FormControl(''),
            ConfirmacaoSenha: new FormControl(''),
            ID: new FormControl(id)
        });
    }

    toggleMostrarSenha(): void {
        this.mostrarSenha = !this.mostrarSenha;
    }

    toggleMostrarConfirmarSenha(): void {
        this.mostrarConfirmarSenha = !this.mostrarConfirmarSenha;
    }

    onClickRecover() {
        this.loadingRecover = true;
        if (
            this.formRecover.value.SenhaNova !==
            this.formRecover.value.ConfirmacaoSenha
        ) {
            this.toastr.error('As senhas não coincidem');
            this.loadingRecover = false;
            return;
        }
        if (this.formRecover.value.SenhaNova.length < 6) {
            this.toastr.error('A senha deve conter no mínimo 6 caracteres');
            this.loadingRecover = false;
            return;
        }

        this.professorService
            .postRedefinirSenha(this.formRecover.value)
            .subscribe({
                next: (response) => {
                    this.toastr.success('Senha redefinida com sucesso');
                    localStorage.setItem('RedefinirSenha', 'false');
                    let idPerfil = JSON.parse(localStorage.getItem('user'))
                        ?.Perfil?.ID;
                    if ([1, 3, 4].includes(idPerfil)) {
                        this.router.navigate(['/dashboard/acesso-avaliador']);
                    } else {
                        this.router.navigate(['/dashboard/acesso-aluno']);
                    }
                    this.loadingRecover = false;
                },
                error: (error) => {
                    if (error?.error?.Message) {
                        this.toastr.error(error?.error?.Message);
                    } else {
                        this.toastr.error('Erro ao redefinir senha');
                    }
                    this.loadingRecover = false;
                }
            });
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    }
}
