<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">Relatórios - Biomarcadores SAFE</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="198px"
                    width="230px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <form
            (ngSubmit)="onClickPesquisar()"
            [formGroup]="formRelatorioBiomarcadores"
        >
            <div class="container-filtro1">
                <h6 class="titulo-options text-azul-claro-plataforma-safe mb-3">
                    Escolha uma opção
                </h6>

                <div class="col-12 mt-6 p-3">
                    <div class="row">
                        <div class="col-md-3 col-12 mt-2" *ngIf="isAdm">
                            <div class="d-flex align-items-center">
                                <input
                                    (click)="onChangeCheckboxValue('Base', 1)"
                                    [checked]="
                                        formRelatorioBiomarcadores.get('Base')
                                            .value == 1
                                    "
                                    class="form-input-checkbox"
                                    id="BaseTodaBase"
                                    type="checkbox"
                                    value="1"
                                />

                                <label
                                    class="mb-0 checkbox-label"
                                    for="BaseTodaBase"
                                    >Toda Base</label
                                >
                            </div>
                        </div>

                        <div class="col-md-3 col-12 mt-2">
                            <div class="d-flex align-items-center">
                                <input
                                    (click)="onChangeCheckboxValue('Base', 2)"
                                    [checked]="
                                        formRelatorioBiomarcadores.get('Base')
                                            .value == 2
                                    "
                                    class="form-input-checkbox"
                                    id="BaseTodaBase"
                                    type="checkbox"
                                    value="2"
                                />

                                <label
                                    class="mb-0 checkbox-label"
                                    for="BaseTodaBase"
                                    >Por Avaliador</label
                                >
                            </div>
                        </div>

                        <div class="col-md-3 col-12 mt-2">
                            <div class="d-flex align-items-center">
                                <input
                                    (click)="onChangeCheckboxValue('Base', 3)"
                                    [checked]="
                                        formRelatorioBiomarcadores.get('Base')
                                            .value == 3
                                    "
                                    class="form-input-checkbox"
                                    id="BasePorGrupo"
                                    type="checkbox"
                                    value="3"
                                />

                                <label
                                    class="mb-0 checkbox-label"
                                    for="BasePorGrupo"
                                    >Por Grupo</label
                                >
                            </div>
                        </div>

                        <div class="col-md-3 col-12 mt-2">
                            <div class="d-flex align-items-center">
                                <input
                                    (click)="onChangeCheckboxValue('Base', 4)"
                                    [checked]="
                                        formRelatorioBiomarcadores.get('Base')
                                            .value == 4
                                    "
                                    class="form-input-checkbox"
                                    id="BasePorCupom"
                                    type="checkbox"
                                    value="4"
                                />

                                <label
                                    class="mb-0 checkbox-label"
                                    for="BasePorCupom"
                                    >Por Cupom</label
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container-tabela mt-5 p-4">
                <h6 class="titulo-options text-azul-claro-plataforma-safe mb-3">
                    Filtrar Usuários - {{ getLabelFiltrarPor() }}
                </h6>

                <div class="col-12 mt-6 p-3">
                    <div class="row">
                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                3
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Grupo</span
                            >
                            <select
                                [value]="
                                    formRelatorioBiomarcadores.get('GrupoID')
                                        .value
                                "
                                class="form-control form-input-select"
                                formControlName="GrupoID"
                            ></select>
                        </div>

                        <div
                            *ngIf="
                                [2].includes(
                                    formRelatorioBiomarcadores.get('Base').value
                                )
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Avaliador</span
                            >

                            <ng-select
                                [items]="listaAvaliadores"
                                bindLabel="Nome"
                                bindValue="ID"
                                class="form-input-box-select"
                                formControlName="AvaliadorID"
                                [virtualScroll]="true"
                                [searchable]="true"
                            >
                            </ng-select>
                        </div>

                        <div
                            *ngIf="
                                [2].includes(
                                    formRelatorioBiomarcadores.get('Base').value
                                )
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Tipo</span
                            >

                            <select
                                [value]="
                                    formRelatorioBiomarcadores.get('Tipo').value
                                "
                                class="form-control form-input-select"
                                formControlName="Tipo"
                            >
                                <option selected="selected" value="1">
                                    Mente
                                </option>
                                <option value="2">Corpo</option>
                            </select>
                        </div>

                        <div
                            *ngIf="
                                [4].includes(
                                    formRelatorioBiomarcadores.get('Base').value
                                )
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Cupom</span
                            >
                            <ng-select
                                [items]="cupons"
                                bindLabel="Descricao"
                                bindValue="ID"
                                class="form-input-box-select"
                                formControlName="CupomID"
                                [virtualScroll]="true"
                                [searchable]="true"
                            >
                            </ng-select>
                        </div>

                        <div
                            *ngIf="
                                [3, 4].includes(
                                    formRelatorioBiomarcadores.get('Base').value
                                )
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Tipo</span
                            >
                            <select
                                [value]="
                                    formRelatorioBiomarcadores.get('Tipo').value
                                "
                                class="form-control form-input-select"
                                formControlName="Tipo"
                            >
                                <option selected="selected" value="1">
                                    Mente
                                </option>
                                <option value="2">Corpo</option>
                            </select>
                        </div>

                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Inicío</span
                            >
                            <input
                                class="form-control form-input-box mb-0"
                                formControlName="PeriodoDe"
                                type="date"
                            />
                        </div>
                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-3 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Fim</span
                            >
                            <input
                                class="form-control form-input-box mb-0"
                                formControlName="PeriodoAte"
                                type="date"
                            />
                        </div>
                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-6 mt-2"
                        ></div>

                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Escolaridade</span
                            >
                            <select
                                class="form-control form-input-select"
                                formControlName="Escolaridade"
                            >
                                <option selected="selected" value="0">
                                    Todas
                                </option>
                                <option value="1">Ensino fundamental</option>
                                <option value="2">Ensino médio</option>
                                <option value="3">Ensino superior</option>
                                <option value="4">Pós graduação</option>
                                <option value="5">Doutorado</option>
                                <option value="6">Pós doutorado</option>
                            </select>
                        </div>

                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Faixa Etária</span
                            >
                            <select
                                class="form-control form-input-select"
                                formControlName="FixaEtaria"
                            >
                                <option selected="selected" value="0">
                                    Todas
                                </option>
                                <option value="1">0 -&gt; 18</option>
                                <option value="2">19 -&gt; 24</option>
                                <option value="3">25 -&gt; 29</option>
                                <option value="4">30 -&gt; 34</option>
                                <option value="5">35 -&gt; 39</option>
                                <option value="6">40 -&gt; 44</option>
                                <option value="7">45 -&gt; 49</option>
                                <option value="8">50 -&gt; 59</option>
                                <option value="9">60 &gt;</option>
                            </select>
                        </div>

                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Sexo</span
                            >
                            <select
                                class="form-control form-input-select"
                                formControlName="Sexo"
                            >
                                <option selected="selected" value="0">
                                    Todos
                                </option>
                                <option value="1">Masculino</option>
                                <option value="2">Feminino</option>
                            </select>
                        </div>

                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >UF</span
                            >
                            <select
                                class="form-control form-input-select"
                                formControlName="UF"
                            >
                                <option selected="selected" value="T">
                                    Todas
                                </option>
                                <option value="AC">AC</option>
                                <option value="AL">AL</option>
                                <option value="AM">AM</option>
                                <option value="AP">AP</option>
                                <option value="BA">BA</option>
                                <option value="CE">CE</option>
                                <option value="DF">DF</option>
                                <option value="ES">ES</option>
                                <option value="GO">GO</option>
                                <option value="MA">MA</option>
                                <option value="MG">MG</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="PA">PA</option>
                                <option value="PB">PB</option>
                                <option value="PE">PE</option>
                                <option value="PI">PI</option>
                                <option value="PR">PR</option>
                                <option value="RJ">RJ</option>
                                <option value="RN">RN</option>
                                <option value="RS">RS</option>
                                <option value="RO">RO</option>
                                <option value="RR">RR</option>
                                <option value="SC">SC</option>
                                <option value="SE">SE</option>
                                <option value="SP">SP</option>
                                <option value="TO">TO</option>
                            </select>
                        </div>
                        <div
                            *ngIf="
                                formRelatorioBiomarcadores.get('Base').value ==
                                1
                            "
                            class="col-12 col-md-2 mt-2"
                        >
                            <span class="text-azul-claro-plataforma-safe"
                                >Tipo</span
                            >
                            <select
                                class="form-control form-input-select"
                                formControlName="Tipo"
                            >
                                <option value="1">Mente</option>
                                <option value="2">Corpo</option>
                            </select>
                        </div>

                        <div class="col-12 col-md-9 mt-2"></div>
                        <div
                            class="col-12 col-md-3 mt-2 d-flex align-items-center justify-content-center"
                        >
                            <button
                                [disabled]="isLoadingRelatorios"
                                class="btn-pesquisar btn btn-secondary btn-block mt-4 w-100"
                                type="submit"
                            >
                                {{
                                    isLoadingRelatorios
                                        ? 'Carregando'
                                        : 'Pesquisar'
                                }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>

        <div id="containerRelatorios" class="container-tabela w-100 mt-5">
            <h6 class="titulo-options text-azul-claro-plataforma-safe mb-3">
                - CHECK-UPS SAFE® Completos:
                {{ relatorios[0]?.TotalBiomarcadores }}
            </h6>

            <div
                class="px-4"
                style="
                    border: 1px solid #d1d1d1;
                    border-radius: 5px;
                    max-height: 700px;
                    overflow-y: scroll;
                "
            >
                <div
                    class="col-12 col-md-3 mt-2"
                    *ngIf="relatorios.length > 0 && !isLoadingRelatorios"
                >
                    <button
                        class="btn btn-secondary btn-block mt-4"
                        style="width: 110px; height: 35px; margin-left: 5px"
                        type="button"
                        (click)="baixarPDF()"
                    >
                        Baixar PDF
                    </button>
                </div>
                <div *ngIf="isLoadingRelatorios" class="text-center w-100 mt-5">
                    <div
                        class="spinner-border text-azul-plataforma-safe"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div
                    *ngIf="!isLoadingRelatorios && relatorios.length == 0"
                    class="text-center w-100 mt-5"
                >
                    <p>
                        Nenhum relatório encontrado, por favor insira um filtro.
                    </p>
                </div>
                <div
                    id="conteudoCompletoParaPDF"
                    *ngFor="let biomarcador of relatorios; let i = index"
                    class="mt-3 p-10"
                >
                    <div>
                        {{ biomarcador.Biomarcador }} <br />
                        <b> {{ biomarcador.PercentualAprovado }}% Aprovado </b>
                        e
                        <b>{{ biomarcador.PercentualReprovado }}% Reprovado</b>
                    </div>

                    <div
                        class="gradient-biomarcador"
                        id="{{ 'gradient-id-' + i }}"
                    >
                        <span
                            class="gradient-biomarcador-texto aprovado"
                            id="{{ 'gradient-text-id-' + i }}"
                        >
                            {{ biomarcador.PercentualAprovado }}%
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
