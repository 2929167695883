<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5">Dúvidas</h5>
                <img
                    src="assets/img/logoSafeTextoBranco.png"
                    alt="plat-safe-logo"
                    height="168px"
                    width="200px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <h4
            class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe p-3"
        >
            14 Passos de como usar a Plataforma SAFE®
        </h4>
        <div
            class="ebooks-container mt-5 d-flex flex-wrap justify-content-between"
        >
            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">1 - Como se cadastrar na Plataforma?</p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique nesse link:
                                    <a
                                        href="https://plataformasafe.com.br/cadastro"
                                        target="_blank"
                                        >https://plataformasafe.com.br/cadastro</a
                                    >
                                </p>
                                <p class="m-0">
                                    - Passo2: Preencha os dados e clique em
                                    cadastrar;
                                </p>
                                <p class="m-0">
                                    - Passo 3: Verifique no seu celular ou
                                    e-mail (em spam e lixeira) o código de
                                    autenticação enviado (sem sair da tela)
                                </p>
                                <p class="m-0">
                                    - Passo 4: Coloque o código e aperte em
                                    validar
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            2 - Para responder o CHECK-UP SAFE® siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Ao validar o código ele entra direto na
                                    Plataforma, preencha as 10 etapas, aperte em
                                    enviar no passo 10
                                </p>
                                <p class="m-0">
                                    - Caso queira entrar depois para responder o
                                    CHECK-UP clique no link: - Passo 1: Clique
                                    nesse link:
                                    <a
                                        href="https://plataformasafe.com.br"
                                        target="_blank"
                                        >https://plataformasafe.com.br</a
                                    >
                                    entre com usuário ou e-mail e a senha que
                                    você criou
                                </p>
                                <p class="m-0">
                                    - Responda os 10 passos e aperte em próximo
                                    e confirmar
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            3 - Para alterar o perfil siga esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo único: Para alterar o perfil siga
                                    esses passos! Clique em mudar perfil
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            4 - Para usar o CHECK-UP com seus clientes siga
                            esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">- Passo 1: Copie seu link</p>
                                <p class="m-0">
                                    - Passo2: Cole no WhatsApp do seu paciente,
                                    clique na barra de mensagens e aperte em
                                    ctrl + V ( ou aperte em colar) envie um
                                    áudio explicando como responder igual você
                                    visualizou no passo 1 E 2(crie seu arquivo
                                    para facilitar o processo)
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            5 - Para visualizar os dados pessoais do cliente
                            siga esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em cadastro> dados
                                    pessoais completos
                                </p>
                                <p class="m-0">
                                    - Passo2: Coloque em nome> digite o nome do
                                    cliente> aperte em Pesquisar
                                </p>

                                <p class="m-0">
                                    - Passo 3: Marque o quadrado>aperte em
                                    editar
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            6 - Para ver as respostas do cliente siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em cadastros> Avaliação
                                    mente
                                </p>
                                <p class="m-0">
                                    - Passo2: Digite o nome do cliente> aperte
                                    em pesquisar
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado> aperte laudo
                                    completo
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            7 - Para ver seu CHECK-UP ou do paciente siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em cadastros> Avaliação
                                    mente
                                </p>
                                <p class="m-0">
                                    - Passo2: Digite o nome do cliente> aperte
                                    em pesquisar
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado> aperte laudo
                                    completo
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            8 - Para preencher os testes físicos siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Para avaliar clique em cadastros>
                                    Avaliação Física
                                </p>
                                <p class="m-0">
                                    - Passo2: Digite o nome do cliente> aperte
                                    em pesquisar
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado> Preencher
                                    avaliação
                                </p>
                                <p class="m-0">
                                    - Passo 4: Preencha as 4 etapas da avaliação
                                    e gere o laudo; para baixar o laudo volte no
                                    passo a passo da dúvida 7
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            9 - Para ajustar algum erro no teste físico siga
                            esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: lique em cadastros> avaliação
                                    física
                                </p>
                                <p class="m-0">
                                    - Passo2: Digite o nome do cliente> aperte
                                    em pesquisar
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado> aperte em
                                    devolver avaliação; agora volte e siga o
                                    passo a passo da dúvida 8!
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            10 - Para fazer o segundo CHECK-UP siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em cadastros> Cadastrar
                                    nova avaliação
                                </p>
                                <p class="m-0">- Passo2: Aperte em novo</p>
                                <p class="m-0">
                                    - Passo 3: Preencha os dados 1: avaliador:
                                    você, 2: tipo de CHECK-UP: individual, 3:
                                    cliente: nome do paciente, 4: tipo de laudo:
                                    completo, 5: Cadastrar e confirme a operação
                                </p>
                                <p class="m-0">
                                    - Passo 4: ATENÇÃO: Após fazer essa operação
                                    envie para seu paciente o link:
                                    <a
                                        href="https://plataformasafe.com.br"
                                        target="_blank"
                                        >https://plataformasafe.com.br</a
                                    >
                                    de acesso para ele entrar na Plataforma com
                                    o login e senha que ele já tem e preencher o
                                    CHECK-UP!
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            11 - Para mudar sua senha siga esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em esqueci senha
                                </p>
                                <p class="m-0">
                                    - Passo2: Coloque seu Email e aperte em
                                    enviar
                                </p>
                                <p class="m-0">
                                    - Passo 3: Copie a senha e entre no link:
                                    <a
                                        href="https://plataformasafe.com.br"
                                        target="_blank"
                                        >https://plataformasafe.com.br</a
                                    >
                                    com seu novo acesso
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            12 - Para mudar a senha do seu paciente siga esses
                            passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Cadastros> dados pessoais
                                    completos
                                </p>
                                <p class="m-0">
                                    - Passo2: Coloque em pesquisa por nome>
                                    coloque o nome do seu paciente e clique em
                                    pesquisar;
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado e clique em
                                    reenviar senha
                                </p>
                                <p class="m-0">
                                    - Passo 4: Pegue a senha dele em cadastros>
                                    reenviar senha do cliente envie a senha no
                                    whatsapp dele com o link de login
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            13 - Para devolver o CHECK-UP para seu paciente
                            ajustar algum erro siga esses passos!
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Clique em cadastros> avaliação
                                    mente
                                </p>
                                <p class="m-0">
                                    - Passo2: Digite o nome do cliente> aperte
                                    em pesquisar;
                                </p>
                                <p class="m-0">
                                    - Passo 3: Marque o quadrado e aperte em
                                    devolver anamnese, avise o paciente para
                                    clicar no link:
                                    <a
                                        href="https://plataformasafe.com.br"
                                        target="_blank"
                                        >https://plataformasafe.com.br</a
                                    >
                                    e ajustar o erro
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>

            <div
                ngbAccordion
                #accordion="ngbAccordion"
                style="margin: 10px; width: 45%"
            >
                <div
                    ngbAccordionItem="first"
                    #first="ngbAccordionItem"
                    [collapsed]="false"
                >
                    <div
                        ngbAccordionHeader
                        ngbAccordionToggle
                        class="accordion-button custom-header justify-content-between"
                    >
                        <p class="m-0">
                            14 - Como você mesmo pode cadastrar seu paciente na
                            Plataforma?
                        </p>
                    </div>
                    <div ngbAccordionCollapse class="accordion-body">
                        <div ngbAccordionBody>
                            <ng-template>
                                <p class="m-0">
                                    - Passo 1: Pegue esses dados da sua aluna:
                                    Nome completo/ Email/ Ocupação atual/
                                    Telefone celular/ País/ Gênero/ Data de
                                    nascimento/ senha entre seis e dez dígitos
                                </p>
                                <p class="m-0">
                                    - Passo2: Copie e cole na barra de busca do
                                    seu navegador o seu link de cadastro;
                                </p>
                                <p class="m-0">
                                    - Passo 3: Clique no link e preencha o
                                    cadastro com as informações da sua aluna
                                </p>
                                <p class="m-0">
                                    - Passo 4: Entre na sua Plataforma e clique
                                    em Relatórios> Código para cadastrar meu
                                    cliente Copie o código de ativação e volta
                                    para tela de cadastro para colar
                                </p>
                                <p class="m-0">- Passo 5: Aperte em Validar</p>
                                <p class="m-0">
                                    - Passo 6: Solicite que seu paciente entre
                                    no link:
                                    <a
                                        href="https://plataformasafe.com.br"
                                        target="_blank"
                                        >https://plataformasafe.com.br</a
                                    >
                                    para responder os questionários (com login e
                                    a senha que você criou).
                                </p>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
